import { OrderStatus } from '@api/types';

export function getOrderStatusLabel(status: OrderStatus) {
  switch (status) {
    case 'draft':
      return 'Bozza';
    case 'sent':
      return 'Inviato';
    case 'confirmed':
      return 'Confermato';
    case 'arrived':
      return 'Arrivato';
    case 'cancelled':
      return 'Cancellato';
    case 'rejected':
      return 'Rifiutato';
    default:
      return '';
  }
}
