import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Supplier } from '@api/types';

async function getMaterialSuppliers(id: string): Promise<Supplier[]> {
  const response = await axios.get<Supplier[]>(`${apiBaseUrl}/materials/${id}/suppliers`);
  return response.data;
}

export function useGetMaterialSuppliers(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetMaterialSuppliers, id],
    queryFn: () => getMaterialSuppliers(id),
    enabled: id !== '',
  });
}
