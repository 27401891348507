import React, { useEffect } from 'react';
import { Breadcrumb, Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetOrder } from '@api/orders/useGetOrder';
import { getOrderStatusLabel } from './utils';
import { PlusOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';
import { isAddMaterialModalOpenState, orderIdState, supplierIdState } from './atoms';
import { AddMaterialModal } from './components/AddMaterialModal';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';
import { ColumnProps } from 'antd/es/table';
import { Order, OrderMaterial } from '@api/types';
import { useDeleteOrderMaterial } from '@api/orders/useDeleteOrderMaterial';
import { useDownloadOrderPdf } from '@api/orders/useDownloadPdf';
import { format } from 'date-fns';
import { useUpdateOrder } from '@api/orders/useUpdateOrder';

export function EditOrder() {
  const params = useParams();

  const setOrderId = useSetRecoilState(orderIdState);
  const setSupplierId = useSetRecoilState(supplierIdState);
  const setIsAddMaterialModalOpenState = useSetRecoilState(isAddMaterialModalOpenState);

  const { data: order, isLoading: isLoadingOrder, refetch: refetchOrder } = useGetOrder(params?.id ?? '');

  const {
    data: materials,
    isLoading: isLoadingMaterials,
    refetch: refetchOrderMaterials,
  } = useGetOrderMaterials(params?.id ?? '');

  const { mutate: deleteOrderMaterial, isSuccess: isSuccessDeleteOrderMaterial } = useDeleteOrderMaterial();
  const { mutate: downloadOrderPdf, isSuccess: isSuccessDownloadPdf } = useDownloadOrderPdf();
  const { mutate: updateOrder, isSuccess: isSuccessUpdateOrder, reset: resetUpdateOrder } = useUpdateOrder();

  useEffect(() => {
    if (params.id) {
      setOrderId(params.id);
    }
  }, []);

  useEffect(() => {
    setSupplierId(order?.supplierId ?? '');
  }, [order]);

  useEffect(() => {
    if (isSuccessDownloadPdf) {
      refetchOrder();
    }
  }, [isSuccessDownloadPdf]);

  useEffect(() => {
    if (isSuccessUpdateOrder) {
      refetchOrder();
    }
  }, [isSuccessUpdateOrder]);

  const add = () => {
    setIsAddMaterialModalOpenState(true);
  };

  const onSend = (order: Order) => {
    updateOrder({ orderId: order.id, order: { status: 'sent' } });
  };

  const onEdit = (orderMaterial: OrderMaterial) => {

  };

  const onDelete = (orderMaterial: OrderMaterial) => {
    deleteOrderMaterial({
      orderId: orderMaterial.orderId,
      materialId: orderMaterial.materialId,
    });
  };

  useEffect(() => {
    if (isSuccessDeleteOrderMaterial) {
      refetchOrderMaterials();
    }
  }, [isSuccessDeleteOrderMaterial]);

  const items = [
    { title: <Link to="/orders">Ordini</Link> },
    { title: `OFABL${order?.year}-${order?.serial}` },
  ];

  const columns: ColumnProps<OrderMaterial>[] = [
    {
      title: 'Codice',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantità',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Prezzo',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          { order?.status === 'draft' && <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button> }
        </Space>
      ),
      align: 'right',
    },
  ];

  if (isLoadingOrder || isLoadingMaterials) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Space size="middle">
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Aggiungi Prodotto
              </Button>
              <Button onClick={() => downloadOrderPdf(order?.id ?? '')}>Scarica PDF</Button>
              <Button type="primary" onClick={() => order && onSend(order)}>Invia</Button>
            </Space>
          }
        >
          <Row gutter={16} style={{ marginBottom: 0 }}>
            <Col span={4}>
              <Typography.Title level={4}><b>Stato:</b> {getOrderStatusLabel(order?.status ?? 'draft')}
              </Typography.Title>
            </Col>

            <Col span={4}>
              <Typography.Title level={4}><b>Fornitore:</b> {order?.supplier.name}</Typography.Title>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: 40 }}>
            <Col span={4}>
              <Typography.Title level={4}><b>Inviato:</b> {order?.sentAt ? format(order.sentAt, 'dd-MM-yyyy') : 'Non Inviato'}
              </Typography.Title>
            </Col>

            <Col span={4}>
              <Typography.Title level={4}><b>Ricevuto:</b> {order?.arrivedAt ? format(order.arrivedAt, 'dd-MM-yyyy') : 'Non Ricevuto'}
              </Typography.Title>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={materials}
            pagination={false}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
      <AddMaterialModal />
    </Row>
  );
}
