import { Alert, Col, Form, Input, Modal } from 'antd';
import { useRecoilState } from 'recoil';
import { isEditReferrerModalOpenState, selectedReferrerState } from './atoms';
import React, { useEffect } from 'react';
import { useUpdateQuotationReferrer } from '@api/quotations/useUpdateQuotationReferrer';
import { useGetQuotationReferrers } from '@api/quotations';

export function UpdateReferrerModal() {
  const [form] = Form.useForm();
  const [selectedReferrer, setSelectedReferrer] = useRecoilState(selectedReferrerState);
  const [isEditReferrerModalOpen, setIsEditReferrerModalOpen] = useRecoilState(isEditReferrerModalOpenState);

  const { refetch: refetchQuotationReferrers } = useGetQuotationReferrers();
  const { mutate: updateQuotationReferrer, isSuccess, error } = useUpdateQuotationReferrer();

  useEffect(() => {
    if (selectedReferrer) {
      form.setFieldValue('description', selectedReferrer.description);
    }
  }, [selectedReferrer]);

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationReferrers().then(() => setIsEditReferrerModalOpen(false));
    }
  }, [isSuccess]);

  const onOk = () => {
    if (selectedReferrer) {
      updateQuotationReferrer({ id: selectedReferrer.id, ...form.getFieldsValue() });
    }
  };

  const onCancel = () => {
    setSelectedReferrer(null);
    form.resetFields();
    setIsEditReferrerModalOpen(false);
  };

  return (
    <Modal title="Modifica Segnalatore" open={isEditReferrerModalOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="edit-referrer" layout="vertical">
          <Form.Item name="description" label="Descrizione">
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
