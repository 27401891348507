import { Alert, Col, Form, Input, Modal } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import React, { useEffect } from 'react';
import { isCreateSourceModalOpenState, selectedSourceState } from './atoms';
import { useGetQuotationSources } from '@api/quotations';
import { useCreateQuotationSource } from '@api/quotations/useCreateQuotationSource';


export function CreateSourceModal() {
  const [form] = Form.useForm();
  const setSelectedSource = useSetRecoilState(selectedSourceState);
  const [isCreateSourceModalOpen, setIsCreateSourceModalOpen] = useRecoilState(isCreateSourceModalOpenState);

  const { refetch: refetchQuotationSources } = useGetQuotationSources();
  const { mutate: createQuotationSource, isSuccess, error, reset } = useCreateQuotationSource();

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationSources().then(() => {
        reset();
        form.resetFields();
        setIsCreateSourceModalOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    createQuotationSource({ ...form.getFieldsValue() });
  };

  const onCancel = () => {
    reset();
    setSelectedSource(null);
    form.resetFields();
    setIsCreateSourceModalOpen(false);
  };

  return (
    <Modal title="Crea Provenienza" open={isCreateSourceModalOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-source" layout="vertical">
          <Form.Item name="description" label="Descrizione">
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-source-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
