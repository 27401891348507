import { atom } from 'recoil';

export const isCreateOrderModalOpenState = atom({
  key: 'isCreateOrderModalOpenState',
  default: false,
});

export const isAddMaterialModalOpenState = atom({
  key: 'isAddMaterialModalOpenState',
  default: false,
});

export const orderIdState = atom<string>({
  key: 'orderIdState',
  default: '',
});

export const supplierIdState = atom<string>({
  key: 'supplierIdState',
  default: '',
});
