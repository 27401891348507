import { Alert, Col, Form, Input, Modal } from 'antd';
import { useRecoilState } from 'recoil';
import { isEditSourceModalOpenState, selectedSourceState } from './atoms';
import React, { useEffect } from 'react';
import { useUpdateQuotationSource } from '@api/quotations/useUpdateQuotationSource';
import { useGetQuotationSources } from '@api/quotations';

export function UpdateSourceModal() {
  const [form] = Form.useForm();
  const [selectedSource, setSelectedSource] = useRecoilState(selectedSourceState);
  const [isEditSourceModalOpen, setIsEditSourceModalOpen] = useRecoilState(isEditSourceModalOpenState);

  const { refetch: refetchQuotationSources } = useGetQuotationSources();
  const { mutate: updateQuotationSource, isSuccess, error } = useUpdateQuotationSource();

  useEffect(() => {
    if (selectedSource) {
      form.setFieldValue('description', selectedSource.description);
    }
  }, [selectedSource]);

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationSources().then(() => setIsEditSourceModalOpen(false));
    }
  }, [isSuccess]);

  const onOk = () => {
    if (selectedSource) {
      updateQuotationSource({ id: selectedSource.id, ...form.getFieldsValue() });
    }
  };

  const onCancel = () => {
    setSelectedSource(null);
    form.resetFields();
    setIsEditSourceModalOpen(false);
  };

  return (
    <Modal title="Modifica Provenienza" open={isEditSourceModalOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="edit-source" layout="vertical">
          <Form.Item name="description" label="Descrizione">
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
