import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';
import { Order } from '@api/types';

export function useUpdateOrder() {
  return useMutation({
    mutationKey: [QueryKey.UpdateOrder],
    mutationFn: ({ orderId, order }: { orderId: string, order: Partial<Order> }) => {
      return axios.put(`${apiBaseUrl}/${ApiPath.Orders}/${orderId}`, order);
    },
  });
}
