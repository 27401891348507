import { Alert, Col, Modal, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import { deleteCustomerModalOpenState } from '@atoms/deleteCustomerModalOpenState';
import { deleteCustomerIdState } from '@atoms/deleteCustomerIdState';
import { useDeleteCustomer } from '@api/customers/useDeleteCustomer';
import React, { useEffect } from 'react';
import { useGetCustomers } from '@api/customers';


export function DeleteCustomerModal() {
  const [isOpen, setIsOpen] = useRecoilState(deleteCustomerModalOpenState);
  const [id, setId] = useRecoilState(deleteCustomerIdState);
  const customers = useGetCustomers();

  const { mutate: deleteCustomer, isSuccess, reset, error } = useDeleteCustomer();

  console.log('error', error?.response?.data.message);

  useEffect(() => {
    if (isSuccess) {
      reset();
      setId('');
      setIsOpen(false);
      customers.refetch();
    }
  }, [isSuccess]);

  const onOk = () => {
    console.log('on ok', id);
    deleteCustomer(id);
  };

  const onCancel = () => {
    reset();
    setId('');
    setIsOpen(false);
  };

  return (
    <Modal title="Elimina Cliente" open={isOpen} onOk={onOk} onCancel={onCancel}>
      <Typography>Sei sicuro di voler eliminare il cliente?</Typography>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
