import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Referrer } from '@aternum-software/abruzzo-lamiere-common';


async function getAllQuotationReferrers() {
  try {
    const response = await axios.get<Referrer[]>(`${apiBaseUrl}/quotation-referrers`);

    return response.data || [];
  } catch (e) {
    return [];
  }
}

export function useGetQuotationReferrers() {
  return useQuery({
    queryKey: [QueryKey.GetQuotationReferrals],
    queryFn: getAllQuotationReferrers,
  });
}
