import { Button, Card, Col, Row } from 'antd';
import { useSetRecoilState } from 'recoil';
import { isCreateCommissionModalOpenState } from './atoms';
import { CreateCommissionModal } from './components/CreateCommissionModal';
import { CommissionsTable } from './components/CommissionsTable';

export function Commissions() {
  const setIsCreateCommissionModalOpen = useSetRecoilState(isCreateCommissionModalOpenState);

  return (
    <Row>
      <Col span={24}>
        <Card title="Commesse"
              extra={<Button type="primary" onClick={() => setIsCreateCommissionModalOpen(true)}>Nuova
                Commessa</Button>}
        >
          <CommissionsTable />
        </Card>
      </Col>
      <CreateCommissionModal />
    </Row>
  );
}
