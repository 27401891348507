import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';

export function useGet<T>(service: ApiPath) {
  return useQuery<T, AxiosError<{ message: string }>>({
    queryKey: ['get-' + service],
    queryFn: async () => {
      const result = await axios.get<T>(`${apiBaseUrl}/${service}`)
      return result.data;
    }
  });
}
