import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Supplier } from '@aternum-software/abruzzo-lamiere-common';
import { ApiPath } from '@constants/index';

async function getSupplier(id: string): Promise<Supplier> {
  const response = await axios.get<Supplier>(`${apiBaseUrl}/${ApiPath.Suppliers}/${id}`);
  return response.data;
}

export function useGetSupplier(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetSupplier, id],
    queryFn: () => getSupplier(id),
    enabled: id !== '',
  });
}
