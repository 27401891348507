import { useGet } from '@api/get';
import { ApiPath } from '@constants/index';
import { Order } from '@api/types';

export * from './auth';
export * from './customers';
export * from './quotations';
export * from './roles';
export * from './warehouses';


// orders
export const useGetOrders = () => useGet<Order[]>(ApiPath.Orders);
