import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Commission } from '@aternum-software/abruzzo-lamiere-common';
import { ApiPath } from '@constants/index';

async function getCommissions(): Promise<Commission[]> {
  const response = await axios.get<Commission[]>(`${apiBaseUrl}/${ApiPath.Commissions}`);
  return response.data;
}

export function useGetCommissions() {
  return useQuery({
    queryKey: [QueryKey.GetCommissions],
    queryFn: getCommissions,
  });
}
