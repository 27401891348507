import { Col, Form, Modal, Select } from 'antd';
import { useRecoilState } from 'recoil';
import { isCreateCommissionModalOpenState } from '../atoms';
import { useGetQuotations } from '@api/quotations';
import { CreateCommissionRequest } from '@aternum-software/abruzzo-lamiere-common';
import { useCreateCommission } from '@api/commissions/useCreateCommission';
import { useEffect } from 'react';
import { useGetCommissions } from '@api/commissions';

export function CreateCommissionModal() {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useRecoilState(isCreateCommissionModalOpenState);
  const { data: quotations, refetch: refetchQuotations } = useGetQuotations({ status: 'sent' });
  const { refetch: refetchCommissions } = useGetCommissions();
  const {
    mutate: createCommission,
    isSuccess: isSuccessCreateCommission,
    reset: resetCreateCommissions,
  } = useCreateCommission();

  const options = quotations?.map(q => ({ label: `${q.year}-${q.serial}`, value: q.id })) ?? [];

  const onFinish = (values: CreateCommissionRequest) => {
    createCommission(values);
  };

  const onCancel = () => {
    setIsOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (isSuccessCreateCommission) {
      resetCreateCommissions();
      refetchQuotations();
      refetchCommissions();
      setIsOpen(false);
      form.resetFields();
    }
  }, [isSuccessCreateCommission]);

  return (
    <Modal title="Nuova Commessa" open={isOpen} onOk={() => form.submit()} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-commission" layout="vertical" onFinish={onFinish}>
          <Form.Item name="quotationId" label="Preventivo">
            <Select options={options} />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
}
