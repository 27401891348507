import React from 'react';
import { Col, Row, Space } from 'antd';


export const CustomersSearch = () => {
  const [value, setValue] = React.useState('');

  const onSearch = (value: string) => {
    console.log('search', value);
  };


  return (
    <Row justify="end" style={{ marginTop: '24px', marginBottom: '36px' }}>
      <Col>
        <Space size="middle">
          {/*<Input.Search*/}
          {/*  placeholder="E-mail, nome, cognome, telefono, ..."*/}
          {/*  value={value}*/}
          {/*  onChange={(e) => setValue(e.target.value)}*/}
          {/*  onSearch={onSearch}*/}
          {/*  enterButton*/}
          {/*  style={{ minWidth: '300px' }}*/}
          {/*/>*/}
          {/*<Button onClick={() => setValue('')}>Reset</Button>*/}
        </Space>
      </Col>
    </Row>
  );
};
