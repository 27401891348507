import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Supplier } from '@aternum-software/abruzzo-lamiere-common';
import { ApiPath } from '@constants/index';

async function getSuppliers(): Promise<Supplier[]> {
  const response = await axios.get<Supplier[]>(`${apiBaseUrl}/${ApiPath.Suppliers}`);
  return response.data;
}

export function useGetSuppliers() {
  return useQuery({
    queryKey: [QueryKey.GetSuppliers],
    queryFn: getSuppliers,
  });
}
