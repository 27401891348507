export * from './constants';
export * from './WorkstationType';

export enum ApiPath {
  Auth = 'auth',
  Commissions = 'commissions',
  Customers = 'customers',
  Orders = 'orders',
  Products = 'products',
  QuotationReferrers = 'quotation-referrers',
  QuotationSources = 'quotation-sources',
  Quotations = 'quotations',
  Suppliers = 'suppliers',
  SupplierMaterials = 'supplier-materials',
  Users = 'users',
  Warehouses = 'warehouses',
  WorkOrderStatuses = 'work-order-statuses',
  WorkOrders = 'work-orders',
}
