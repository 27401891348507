import { Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { getQuotationStatusLabel } from '../quotations/utils';
import { CreateOrderModal } from './components/CreateOrderModal';
import { Order } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { useGetOrders } from '@api/index';

export function OrdersReception() {
  const navigate = useNavigate();
  const { data: orders } = useGetOrders();

  const onCheck = (orderId: string) => {
    navigate(`/orders-reception/${orderId}`);
  };

  const columns: ColumnProps<Order>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>OFABL{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getQuotationStatusLabel(text)}</Typography>,
    },
    {
      title: 'Fornitore',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier) => <Typography>{supplier?.name ?? ''}</Typography>,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onCheck(record.id)}>Controlla</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Ordini a Fornitore">
          <Table
            columns={columns}
            dataSource={orders?.filter(o => ['sent', 'confirmed'].includes(o.status))}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: orders?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
      <CreateOrderModal />
    </Row>
  );
}
