export enum QueryKey {
  // customers
  GetCustomers = 'GetCustomers',
  GetCustomer = 'GetCustomer',
  UpdateCustomer = 'UpdateCustomer',
  CreateCustomer = 'CreateCustomer',
  DeleteCustomer = 'DeleteCustomer',

  // products
  GetProducts = 'GetProducts',
  GetProduct = 'GetProduct',
  CreateProduct = 'CreateProduct',

  // quotations
  CreateQuotation = 'CreateQuotation',
  CreateQuotationProduct = 'CreateQuotationProduct',
  CreateQuotationReferrer = 'CreateQuotationReferrer',
  CreateQuotationSource = 'CreateQuotationSource',
  DeleteQuotationReferrer = 'DeleteQuotationReferrer',
  DeleteQuotationSource = 'DeleteQuotationSource',
  GetQuotation = 'GetQuotation',
  GetQuotationFiles = 'GetQuotationFiles',
  DeleteQuotationFile = 'DeleteQuotationFile',
  GetQuotationReferrals = 'GetQuotationReferrals',
  GetQuotationSources = 'GetQuotationSources',
  GetQuotationStatuses = 'GetQuotationStatuses',
  GetQuotations = 'GetQuotations',
  UpdateQuotation = 'UpdateQuotation',
  UpdateQuotationProduct = 'UpdateQuotationProduct',
  UpdateQuotationProductParam = 'UpdateQuotationProductParam',
  UpdateQuotationReferrer = 'UpdateQuotationReferrer',
  UpdateQuotationSource = 'UpdateQuotationSource',

  // commissions
  GetCommissions = 'GetCommissions',
  GetCommission = 'GetCommission',
  CreateCommission = 'CreateCommission',
  GetCommissionFiles = 'GetCommissionFiles',
  GetCommissionMaterials = 'GetCommissionMaterials',

  // list options
  GetListOptionTypes = 'GetListOptionTypes',
  GetListOptions = 'GetListOptions',
  UpdateListOption = 'UpdateListOption',

  // users
  GetProfile = 'GetProfile',
  GetUser = 'GetUser',
  GetUsers = 'GetUsers',
  UpdateUser = 'UpdateUser',
  UserChangePassword = 'UserChangePassword',

  // auth
  Register = 'Register',

  // work-orders
  GetWorkOrders = 'GetWorkOrders',
  GetWorkOrderStatuses = 'GetWorkOrderStatuses',
  GetWorkOrderById = 'GetWorkOrderById',
  CreateWorkOrder = 'CreateWorkOrder',
  GetFreeQuotations = 'GetFreeQuotations',
  WorkOrderAddQuotation = 'WorkOrderAddQuotation',
  WorkOrderAddProduct = 'WorkOrderAddProduct',

  // warehouse
  GetWarehouses = 'GetWarehouses',
  GetWarehouseSupplies = 'GetWarehouseSupplies',
  AddWarehouseMaterial = 'AddWarehouseMaterial',
  WarehouseLoading = 'WarehouseLoading',

  // orders
  GetOrder = 'GetOrder',
  GetOrders = 'GetOrders',
  CreateOrder = 'CreateOrder',
  GetOrderMaterials = 'GetOrderMaterials',
  CreateOrderMaterial = 'CreateOrderMaterial',
  DeleteOrderMaterial = 'DeleteOrderMaterial',
  DownloadOrderPdf = 'DownloadOrderPdf',
  UpdateOrder = 'UpdateOrder',

  // suppliers
  GetSupplier = 'GetSupplier',
  GetSuppliers = 'GetSuppliers',
  CreateSupplier = 'CreateSupplier',
  UpdateSupplier = 'UpdateSupplier',
  DeleteSupplier = 'DeleteSupplier',
  GetSupplierMaterials = 'GetSupplierMaterials',
  GetMaterialSuppliers = 'GetMaterialSuppliers',
  CreateSupplierMaterial = 'CreateSupplierMaterial',
  UpdateSupplierMaterial = 'UpdateSupplierMaterial',
  DeleteSupplierMaterial = 'DeleteSupplierMaterial',
  GetSupplierMaterial = 'GetSupplierMaterial',

  // materials
  GetMaterials = 'GetMaterials',
  GetMaterial = 'GetMaterial',
  UpdateMaterial = 'UpdateMaterial',
}
