import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Material } from '@aternum-software/abruzzo-lamiere-common';

async function getMaterial(id: string): Promise<Material> {
  const response = await axios.get<Material>(`${apiBaseUrl}/materials/${id}`);
  return response.data;
}

export function useGetMaterial(id: string) {
  return useQuery({
    queryKey: [QueryKey.GetMaterial, id],
    queryFn: () => getMaterial(id),
  });
}
