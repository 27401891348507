import { Alert, Col, Form, InputNumber, Modal, Select } from 'antd';
import { useRecoilState } from 'recoil';
import React, { useEffect } from 'react';
import { isAddMaterialModalOpenState, orderIdState, supplierIdState } from '../atoms';
import { useGetSupplierMaterials } from '@api/suppliers/useGetSupplierMaterials';
import { useCreateOrderMaterial } from '@api/orders/useCreateOrderMaterial';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';

export function AddMaterialModal() {
  const [form] = Form.useForm();
  const [orderId] = useRecoilState(orderIdState);
  const [supplierId] = useRecoilState(supplierIdState);
  const [isOpen, setIsOpen] = useRecoilState(isAddMaterialModalOpenState);

  const { data: supplierMaterials, isLoading: isLoadingSuppliers } = useGetSupplierMaterials(supplierId);
  const { refetch: refetchOrderMaterials } = useGetOrderMaterials(orderId);
  const { mutate: addMaterial, isSuccess, reset, error } = useCreateOrderMaterial();

  useEffect(() => {
    if (isSuccess) {
      refetchOrderMaterials().then(() => {
        reset();
        form.resetFields();
        setIsOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    form.validateFields()
      .then(() => {
        addMaterial({ orderId, ...form.getFieldsValue() });
      })
      .catch((err => {
      }));
  };

  const onCancel = () => {
    reset();
    form.resetFields();
    setIsOpen(false);
  };

  if (isLoadingSuppliers) {
    return null;
  }

  return (
    <Modal title="Aggiungi Materiale" open={isOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="add-material" layout="vertical">
          <Form.Item
            name="materialId"
            label="Materiale"
            required
            rules={[{ required: true, message: 'Per favore seleziona un materiale' }]}
          >
            <Select options={supplierMaterials?.map(s => ({ label: s.description, value: s.materialId }))} />
          </Form.Item>

          <Form.Item
            name="quantity"
            label="Quantità"
            initialValue={1}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
