import { Alert, Col, Modal, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import { isDeleteReferrerModalOpenState, selectedReferrerState } from './atoms';
import React, { useEffect } from 'react';
import { useDeleteQuotationReferrer, useGetQuotationReferrers } from '@api/quotations';

export function DeleteReferrerModal() {
  const [selectedReferrer, setSelectedReferrer] = useRecoilState(selectedReferrerState);
  const [isDeleteReferrerModalOpen, setIsDeleteReferrerModalOpen] = useRecoilState(isDeleteReferrerModalOpenState);

  const { refetch: refetchQuotationReferrers } = useGetQuotationReferrers();
  const { mutate: deleteQuotationReferrer, isSuccess, error, reset } = useDeleteQuotationReferrer();

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationReferrers().then(() => {
        reset();
        setIsDeleteReferrerModalOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    if (selectedReferrer) {
      deleteQuotationReferrer(selectedReferrer.id);
    }
  };

  const onCancel = () => {
    reset();
    setSelectedReferrer(null);
    setIsDeleteReferrerModalOpen(false);
  };

  return (
    <Modal title="Elimina Segnalatore" open={isDeleteReferrerModalOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler eliminare il segnalatore?</Typography>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
