import { useCallback } from 'react';
import { notification } from 'antd';

export function useNotification() {
  const [api, contextHolder] = notification.useNotification();

  const openInfoMessage = useCallback(
    (message: string) => api.info({
      message,
      placement: 'topRight',
    }),
    [api],
  );

  return {
    contextHolder,
    openInfoMessage,
  };
}
