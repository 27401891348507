import React from 'react';
import { Button, Space, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Customer } from '@aternum-software/abruzzo-lamiere-common';
import { useGetCustomers } from '@api/customers';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deleteCustomerModalOpenState } from '@atoms/deleteCustomerModalOpenState';
import { deleteCustomerIdState } from '@atoms/deleteCustomerIdState';


export function CustomersTable() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetCustomers();
  const [, setDeleteCustomerModalOpen] = useRecoilState(deleteCustomerModalOpenState);
  const [, setDeleteCustomerId] = useRecoilState(deleteCustomerIdState);

  const onEdit = (id: string) => {
    navigate(`/registry/customers/${id}`);
  };

  const onDelete = (id: string) => {
    setDeleteCustomerId(id);
    setDeleteCustomerModalOpen(true);
  };

  const columns: ColumnProps<Customer>[] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (_, customer) => `${customer.name} ${customer.firstName} ${customer.lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: data?.length ?? 0,
        }}
        rowKey="id"
        size="small"
      />
    </Spin>
  );
}
