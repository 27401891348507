import { atom } from 'recoil';

export const selectedWarehouseIdState = atom({
  key: 'selectedWarehouseIdState',
  default: '',
});

export const isAddWarehouseMaterialModalOpenState = atom({
  key: 'isAddWarehouseMaterialModalOpenState',
  default: false,
});

export const setIsLoadingWarehouseModalOpenState = atom({
  key: 'setIsLoadingWarehouseModalOpenState',
  default: false,
});
