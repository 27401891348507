import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Row, theme, Typography } from 'antd';
import { MenuProps } from 'antd/lib';
import {
  DashboardOutlined,
  GroupOutlined,
  LoginOutlined,
  OrderedListOutlined,
  PrinterOutlined,
  ProductOutlined,
  SettingOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import logo from '../../img/logo.png';
import { useWorkstationType } from '@hooks/useWorkstationType';
import { WorkstationType } from '@constants/WorkstationType';
import { useRecoilState } from 'recoil';
import { userRolesState } from '@atoms/userRolesState';


const { Sider } = Layout;

const dashboardItem = {
  key: 'dashboard',
  label: <Link to="/">Dashboard</Link>,
  icon: React.createElement(DashboardOutlined),
};

const registryItem = {
  key: 'registry',
  label: <Typography>Anagrafiche</Typography>,
  icon: React.createElement(PrinterOutlined),
  children: [
    { label: <Link to="/registry/customers">Clienti</Link>, key: '/registry/customers' },
    { label: <Link to="/registry/suppliers">Fornitori</Link>, key: '/registry/suppliers' },
    { label: <Link to="/registry/materials">Materiali</Link>, key: '/registry/materials' },
    { label: <Link to="/registry/referrers">Segnalatori</Link>, key: '/registry/referrers' },
    { label: <Link to="/registry/sources">Provenienze</Link>, key: '/registry/sources' },
  ],
};

const quotesItem = {
  key: 'quotes',
  label: <Link to="/quotes">Preventivi</Link>,
  icon: React.createElement(PrinterOutlined),
};

const commissionsItem = {
  key: 'commissions',
  label: <Link to="/commissions">Commesse</Link>,
  icon: React.createElement(PrinterOutlined),
};

const ordersItem = {
  key: 'orders',
  label: <Link to="/orders">Ordini a Fornitore</Link>,
  icon: React.createElement(PrinterOutlined),
};

const ordersReceptionItem = {
  key: 'orders-reception',
  label: <Link to="/orders-reception">Ricezione Ordini</Link>,
  icon: React.createElement(PrinterOutlined),
};

const warehouseItem = {
  key: 'warehouse',
  label: <Link to="/warehouse">Magazzino</Link>,
  icon: React.createElement(GroupOutlined),
};


const productsItem = {
  key: 'products',
  label: <Link to="/products">Prodotti</Link>,
  icon: React.createElement(ProductOutlined),
};

const priceListItem = {
  key: 'price-list',
  label: <Link to="/price-list">Listini Prezzi</Link>,
  icon: React.createElement(OrderedListOutlined),
};

const workOrdersItem = {
  key: 'work-orders',
  label: <Link to="/work-orders">Ordini di Lavoro</Link>,
  icon: React.createElement(GroupOutlined),
};

const laserCutItem = {
  key: 'laser-cut',
  label: <Link to="/laser-cut">Taglio Laser</Link>,
  icon: React.createElement(ToolOutlined),
};

const bendingItem = {
  key: 'bending',
  label: <Link to="/bending">Piegatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const weldingItem = {
  key: 'welding',
  label: <Link to="/welding">Saldatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const assemblyItem = {
  key: 'assembly',
  label: <Link to="/assembly">Assemblaggio</Link>,
  icon: React.createElement(ToolOutlined),
};

const settingsItem = {
  key: 'settings',
  label: <Link to="/settings">Settings</Link>,
  icon: React.createElement(SettingOutlined),
};

const logoutItem = {
  key: 'logout',
  label: <Link to="/logout">Logout</Link>,
  icon: React.createElement(LoginOutlined),
};


export const SideNavigation: React.FC = () => {
  const location = useLocation();
  const [userRoles] = useRecoilState(userRolesState);
  const { workstationType } = useWorkstationType();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps['items'] = useMemo(() => {
    const items = [dashboardItem];

    if (workstationType === WorkstationType.Admin && userRoles.some(r => r.role === 'admin')) {
      items.push(productsItem);
      items.push(priceListItem);
      items.push(quotesItem);
      items.push(commissionsItem);
      items.push(warehouseItem);
      items.push(ordersItem);
      items.push(workOrdersItem);
    }

    if (userRoles.some(r => ['admin', 'worker'].includes(r.role))) {
      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.LaserCut) {
        items.push(laserCutItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Bending) {
        items.push(bendingItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Welding) {
        items.push(weldingItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Assembly) {
        items.push(assemblyItem);
        items.push(ordersReceptionItem);
      }
    }

    if (workstationType === WorkstationType.Admin && userRoles.some(r => r.role === 'admin')) {
      items.push(registryItem);
    }

    items.push(settingsItem);
    items.push(logoutItem);

    return items;
  }, [userRoles, workstationType]);

  return (
    <Sider
      style={{ background: colorBgContainer }}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => console.log(broken)}
      onCollapse={(collapsed, type) => console.log(collapsed, type)}
    >
      <Row justify="center" style={{ margin: '24px 0' }}>
        <img width={160} src={logo} alt="logo abruzzo lamiere" />
      </Row>

      <Menu mode="inline" selectedKeys={[location.pathname]} items={items} />
    </Sider>
  );
};
