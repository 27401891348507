import { Alert, Col, Form, Modal, Select } from 'antd';
import { useRecoilState } from 'recoil';
import React, { useEffect } from 'react';
import { isCreateCommissionOrderModalOpenState } from '../atoms';
import { useCreateOrder } from '@api/orders/useCreateOrder';
import { useGetMaterialSuppliers } from '@api/materials/useGetMaterialSuppliers';
import { useParams } from 'react-router-dom';
import { useGetCommissionMaterials } from '@api/commissions/useGetCommissionMaterials';

interface Props {
  materialId: string;
}

export function CreateCommissionOrderModal({ materialId }: Props) {
  const params = useParams();
  const [form] = Form.useForm();

  const { data: suppliers, isLoading: isLoadingSuppliers } = useGetMaterialSuppliers(materialId);
  console.log('suppliers:', suppliers);

  const [isOpen, setIsOpen] = useRecoilState(isCreateCommissionOrderModalOpenState);

  const { refetch: refetchOrders } = useGetCommissionMaterials(params?.id ?? '');
  const { mutate: createOrder, isSuccess, error, reset } = useCreateOrder();

  useEffect(() => {
    if (isSuccess) {
      refetchOrders().then(() => {
        reset();
        form.resetFields();
        setIsOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    form.validateFields().then(() => {
      createOrder(form.getFieldsValue());
    })
      .catch((err => {
      }));
  };

  const onCancel = () => {
    reset();
    form.resetFields();
    setIsOpen(false);
  };

  if (isLoadingSuppliers) {
    return null;
  }

  return (
    <Modal title="Crea Ordine" open={isOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-commission-order" layout="vertical">
          <Form.Item
            name="supplierId"
            label="Fornitore"
            required
            rules={[{ required: true, message: 'Per favore inserisci un fornitore' }]}
          >
            <Select options={suppliers?.map(s => ({ label: s.name, value: s.id }))} />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
