import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { QueryKey } from '@constants/queries';
import { Customer } from '@aternum-software/abruzzo-lamiere-common';
import { ApiPath } from '@constants/index';

async function getCustomer(id: string): Promise<Customer | undefined> {
  try {
    console.log('fuck');
    const response = await axios.get(`${apiBaseUrl}/${ApiPath.Customers}/${id}`);

    return response.data;
  } catch (e) {
    return undefined;
  }
}

export const useGetCustomer = (id: string) => useQuery({
  queryKey: [QueryKey.GetCustomer, id],
  queryFn: () => getCustomer(id),
  enabled: id !== '',
});
