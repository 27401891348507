export function getCommissionStatusLabel(status: string) {
  switch (status) {
    case 'engineering':
      return 'Progettazione';
    case 'laser-cut':
      return 'Taglio Laser';
    case 'bending':
      return 'Piegatura';
    case 'welding':
      return 'Saldatura';
    case 'painting':
      return 'Verniciatura';
    case 'assembly':
      return 'Assemblaggio';
    case 'packaging':
      return 'Imballaggio';
    case 'ready-for-delivery':
      return 'Pronta Alla Consegna';
    case 'delivered':
      return 'Consegnata';
    default:
      return '';
  }
}
