import { CreateCommissionRequest } from '@aternum-software/abruzzo-lamiere-common';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { ApiPath } from '@constants/index';

function createCommission(payload: CreateCommissionRequest) {
  return axios.post(`${apiBaseUrl}/${ApiPath.Commissions}`, payload);
}

export function useCreateCommission() {
  return useMutation({
    mutationKey: [QueryKey.CreateCommission],
    mutationFn: createCommission,
  });
}
