import React, { useEffect } from 'react';
import { Alert, Col, Modal, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import { isDeleteSupplierModalOpenState, selectedSupplierState } from './atoms';
import { useGetSuppliers } from '@api/suppliers/useGetSuppliers';
import { useDeleteSupplier } from '@api/suppliers/useDeleteSupplier';

export function DeleteSupplierModal() {
  const [supplier, setSupplier] = useRecoilState(selectedSupplierState);
  const [open, setOpen] = useRecoilState(isDeleteSupplierModalOpenState);

  const { refetch } = useGetSuppliers();
  const { mutate, isSuccess, error, reset } = useDeleteSupplier();

  useEffect(() => {
    if (isSuccess) {
      refetch().then(() => {
        reset();
        setOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    if (supplier) {
      mutate(supplier.id);
    }
  };

  const onCancel = () => {
    reset();
    setSupplier(null);
    setOpen(false);
  };

  return (
    <Modal title="Elimina Fornitore" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler eliminare il seguente fornitore?</Typography>

        <Typography.Title level={5} style={{ textAlign: 'center' }}>"{supplier?.name}"</Typography.Title>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
