import { Breadcrumb, Button, Card, Col, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetOrder } from '@api/orders/useGetOrder';
import { useUpdateOrder } from '@api/orders/useUpdateOrder';
import { useGetOrderMaterials } from '@api/orders/useGetOrderMaterials';

export function CheckOrder() {
  const navigate = useNavigate();
  const params = useParams();

  const { data: order } = useGetOrder(params?.id ?? '');
  const { data: materials } = useGetOrderMaterials(params?.id ?? '');
  console.log('order', order);
  console.log('materials', materials);

  const { mutate, isSuccess, reset } = useUpdateOrder();

  const onReceive = () => {
    mutate({ orderId: order?.id ?? '', order: { status: 'arrived' } });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      navigate(`/orders-reception`);
    }
  }, [isSuccess]);

  const items = [
    { title: <Link to="/orders-reception">Ordini</Link> },
    { title: `OFABL${order?.year}-${order?.serial}` },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Space size="middle">
              <Button type="dashed" onClick={onReceive} block icon={<PlusOutlined />}>
                Conferma
              </Button>
            </Space>
          }
        >
        </Card>
      </Col>
    </Row>
  );
}
