import { Button, Space, Table, TableColumnProps, Typography } from 'antd';
import { Commission } from '@aternum-software/abruzzo-lamiere-common';
import { useGetCommissions } from '@api/commissions';
import { getCommissionStatusLabel } from '../utils';
import { useNavigate } from 'react-router-dom';

export function CommissionsTable() {
  const navigate = useNavigate();
  const { data: commissions } = useGetCommissions();
  console.log('commissions', commissions);

  const onEdit = (commission: Commission) => {
    navigate(`/commissions/${commission.id}`);
  };

  const onDelete = (commission: Commission) => {
  };

  const columns: TableColumnProps<Commission>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Preventivo',
      dataIndex: 'quotation',
      key: 'quotation',
      render: (_, record) => <Typography>{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getCommissionStatusLabel(text)}</Typography>,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={commissions}
      pagination={{
        pageSizeOptions: ['10', '20', '30', '50'],
        responsive: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
        total: commissions?.length ?? 0,
      }}
      rowKey="id"
      size="small"
    />
  );
}
