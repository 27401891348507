import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAxiosInterceptor } from '@hooks/useAxiosInterceptor';
import { isLoggedInState } from '@atoms/isLoggedInState';
import { MainLayout } from './layouts/MainLayout';
import { BaseLayout } from './layouts/BaseLayout';
import {
  Assembly,
  Customer,
  Customers,
  Dashboard,
  EditUser,
  LaserCut,
  Login,
  Logout,
  Referrers,
  Settings,
  Sources,
  Suppliers,
  Welding,
} from './routes';
import { Quotations } from './routes/quotations/Quotations';
import { EditQuotation } from './routes/quotations/EditQuotation';
import { Products } from './routes/products/Products';
import { WorkOrders } from './routes/work-orders/WorkOrders';
import { PriceList } from './routes/PriceList';
import { EditProduct } from './routes/products/EditProduct';
import { EditWorkOrder } from './routes/work-orders/EditWorkOrder';
import { WorkOrderProduct } from './routes/work-orders/WorkOrderProduct';
import { useInitApp } from '@hooks/useInitApp';
import { Commissions } from './routes/commissions/Commissions';
import { Orders } from './routes/orders/Orders';
import { Warehouse } from './routes/warehouse/Warehouse';
import { OrdersReception } from './routes/orders/OrdersReception';
import { Bending } from './routes/Bending';
import { EditOrder } from './routes/orders/EditOrder';
import { Supplier } from './routes/registry/Supplier';
import { Materials } from './routes/registry/Materials';
import { Material } from './routes/registry/Material';
import { CommissionRoute } from './routes/commissions/CommissionRoute';
import { CheckOrder } from './routes/orders/CheckOrder';


const privateRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: 'registry',
          children: [
            { path: 'customers', element: <Customers /> },
            { path: 'customers/:id', element: <Customer /> },
            { path: 'suppliers', element: <Suppliers /> },
            { path: 'suppliers/:id', element: <Supplier /> },
            { path: 'referrers', element: <Referrers /> },
            { path: 'sources', element: <Sources /> },
            { path: 'materials', element: <Materials /> },
            { path: 'materials/:id', element: <Material /> },
          ],
        },
        {
          path: 'products',
          children: [
            { index: true, element: <Products /> },
            { path: ':id', element: <EditProduct /> },
          ],
        },
        {
          path: 'price-list',
          element: <PriceList />,
        },
        {
          path: 'quotes',
          element: <Quotations />,
        },
        {
          path: 'quotes/:id',
          element: <EditQuotation />,
        },
        {
          path: 'commissions',
          children: [
            { index: true, element: <Commissions /> },
            { path: ':id', element: <CommissionRoute /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { index: true, element: <Orders /> },
            { path: ':id', element: <EditOrder /> },
          ],
        },
        {
          path: 'orders-reception',
          children: [
            { index: true, element: <OrdersReception /> },
            { path: ':id', element: <CheckOrder /> },
          ],
        },
        {
          path: 'warehouse',
          element: <Warehouse />,
        },
        {
          path: 'work-orders',
          children: [
            { index: true, element: <WorkOrders /> },
            { path: ':id', element: <EditWorkOrder /> },
            { path: ':id/quotations/:quotationId/product/:productId', element: <WorkOrderProduct /> },
          ],
        },
        {
          path: 'laser-cut',
          element: <LaserCut />,
        },
        {
          path: 'Bending',
          element: <Bending />,
        },
        {
          path: 'welding',
          element: <Welding />,
        },
        {
          path: 'assembly',
          element: <Assembly />,
        },
        {
          path: 'settings',
          children: [
            { index: true, element: <Settings /> },
            { path: 'users/:id', element: <EditUser /> },
          ],
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
    },
  ]);

const publicRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <BaseLayout />,
      children: [{ index: true, element: <Login /> }],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

export const RootRouter = () => {
  const { isLoading } = useAxiosInterceptor();
  const [isLoggedIn] = useRecoilState(isLoggedInState);

  useInitApp(isLoggedIn);

  if (isLoading) {
    return null;
  }

  return (
    <RouterProvider router={isLoggedIn ? privateRoutes : publicRoutes} />
  );
};
