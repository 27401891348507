import { Breadcrumb, Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetSupplier } from '@api/suppliers/useGetSupplier';
import { useUpdateSupplier } from '@api/suppliers/useUpdateSupplier';
import { CreateSupplierRequest, SupplierMaterial } from '@api/types';
import { useNotification } from '@hooks/useNotification';
import { useGetSupplierMaterials } from '@api/suppliers/useGetSupplierMaterials';
import { ColumnProps } from 'antd/es/table';
import { AddSupplierMaterialModal } from './components/suppliers/AddSupplierMaterialModal';
import { isAddSupplierMaterialModalOpenState, isUpdateSupplierMaterialModalOpenState } from './components/suppliers';
import { useSetRecoilState } from 'recoil';
import { UpdateSupplierMaterialModal } from './components/suppliers/UpdateSupplierMaterialModal';


export function Supplier() {
  const params = useParams();
  const { contextHolder, openInfoMessage } = useNotification();
  const [supplierMaterialId, setSupplierMaterialId] = useState<string>('');

  const setIsAddSupplierMaterialModalOpenState = useSetRecoilState(isAddSupplierMaterialModalOpenState);
  const setIsUpdateSupplierMaterialModalOpenState = useSetRecoilState(isUpdateSupplierMaterialModalOpenState);

  const { data: supplier, isLoading: isLoadingSupplier, refetch } = useGetSupplier(params?.id ?? '');
  const { data: materials, isLoading: isLoadingMaterials } = useGetSupplierMaterials(params?.id ?? '');
  const { mutate, isSuccess } = useUpdateSupplier();

  const items = [
    { title: <Link to="/registry/suppliers">Fornitori</Link> },
    { title: supplier?.name ?? '' },
  ];

  const onFinish = (payload: CreateSupplierRequest) => {
    if (supplier?.id) {
      mutate({ id: supplier.id, payload });
    }
  };

  const onEdit = (material: SupplierMaterial) => {
    setSupplierMaterialId(material.id);
    setIsUpdateSupplierMaterialModalOpenState(true);
  };

  const onDelete = (material: SupplierMaterial) => {
    console.log('material', material);
  };

  const onAddMaterial = () => {
    setIsAddSupplierMaterialModalOpenState(true);
  };

  useEffect(() => {
    if (isSuccess) {
      openInfoMessage('Fornitore aggiornato con successo');
      refetch();
    }
  }, [isSuccess]);

  if (isLoadingSupplier) {
    return null;
  }

  const columns: ColumnProps<SupplierMaterial>[] = [
    {
      title: 'Codice',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Prezzo',
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => <Typography>{record.price}</Typography>,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={<Button type="primary" htmlType="submit" form="supplier">Salva</Button>}
        >
          <Form name="supplier" initialValues={supplier} onFinish={onFinish} layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="name" label="Nome">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="vatNumber" label="Partita IVA">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="phoneNumber" label="Telefono" initialValue={supplier?.phoneNumber}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="email" label="Email" initialValue={supplier?.email}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="address" label="Indirizzo" initialValue={supplier?.address}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="city" label="Città" initialValue={supplier?.city}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="province" label="Provincia" initialValue={supplier?.province}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="postalCode" label="CAP" initialValue={supplier?.postalCode}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="country" label="Nazione" initialValue={supplier?.country}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>

        <Divider />

        <Card title="Materiali" extra={<Button type="primary" onClick={onAddMaterial}>Aggiungi Materiale</Button>}>
          <Spin spinning={isLoadingMaterials}>
            <Table
              columns={columns}
              dataSource={materials}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: materials?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>
      {contextHolder}
      <AddSupplierMaterialModal supplierId={supplier?.id ?? ''} />
      <UpdateSupplierMaterialModal supplierId={supplier?.id ?? ''} supplierMaterialId={supplierMaterialId} />
    </Row>
  );
}
