import { useWorkstationType } from '@hooks/useWorkstationType';
import { useGetProfile } from '@api/UsersApi';
import { useGetUserRolesQuery } from '@api/roles';
import { useSetRecoilState } from 'recoil';
import { userRolesState } from '@atoms/userRolesState';
import { useEffect } from 'react';
import { userProfileState } from '@atoms/userProfileState';

export function useInitApp(enabled = false) {
  useWorkstationType();

  const profile = useGetProfile({ enabled });
  const roles = useGetUserRolesQuery(profile.data?.id ?? '');

  const setUserProfile = useSetRecoilState(userProfileState);
  const setUserRoles = useSetRecoilState(userRolesState);

  useEffect(() => {
    if (roles.isSuccess && Array.isArray(roles.data)) {
      setUserRoles(roles.data);
    }

    if (profile.data) {
      setUserProfile(profile.data);
    }
  }, [roles, profile]);
}
