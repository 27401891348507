import { Card, Col, Row, Tabs, type TabsProps } from 'antd';
import { ProfileTab } from './components/ProfileTab';
import { UsersTab } from './components/UsersTab';
import { SettingsTabs } from '@constants/NavigationTabs';
import { useEffect, useMemo, useState } from 'react';
import { WorkstationTab } from './components/WorkstationTab';
import { useRecoilState } from 'recoil';
import { userRolesState } from '@atoms/userRolesState';
import { useSearchParams } from 'react-router-dom';


export function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userRoles] = useRecoilState(userRolesState);

  const tabItems = useMemo(() => {
    const items: TabsProps['items'] = [{
      label: 'Profile',
      key: SettingsTabs.Profile,
      children: <ProfileTab />,
    }];

    if (userRoles.some(r => r.role === 'admin')) {
      items.push({
        label: 'Users',
        key: SettingsTabs.Users,
        children: <UsersTab />,
      }, {
        label: 'Workstation',
        key: SettingsTabs.Workstation,
        children: <WorkstationTab />,
      });
    }

    return items;
  }, [userRoles]);

  const [activeTabKey, setActiveTabKey] = useState<SettingsTabs>(SettingsTabs.Profile);

  useEffect(() => {
    const active = searchParams.get('active') as SettingsTabs;

    if (active) {
      setActiveTabKey(active);
      setSearchParams({});
    }
  }, []);

  const onTabChange = (tabKey: string) => {
    setActiveTabKey(tabKey as SettingsTabs);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          style={{ minHeight: '90vh' }}
        >
          <Tabs
            defaultActiveKey={SettingsTabs.Profile}
            activeKey={activeTabKey}
            onChange={onTabChange}
            items={tabItems}
          />
        </Card>
      </Col>
    </Row>
  );
}
