import { Alert, Col, Form, InputNumber, Modal } from 'antd';
import { useRecoilState } from 'recoil';
import { useGetWarehouseMaterials, useGetWarehouses, useWarehouseLoading } from '@api/warehouses';
import { setIsLoadingWarehouseModalOpenState } from '../atoms';
import { useEffect } from 'react';

export interface WarehouseLoadingModalProps {
  warehouseId: string;
  warehouseMaterialId: string;
  action: 'load' | 'unload';
}

export function WarehouseLoadingModal({ action, warehouseId, warehouseMaterialId }: WarehouseLoadingModalProps) {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useRecoilState(setIsLoadingWarehouseModalOpenState);

  const { data: warehouses } = useGetWarehouses();
  const { refetch } = useGetWarehouseMaterials(warehouses?.[0].id ?? '');

  const { mutate, isSuccess, error, reset } = useWarehouseLoading();

  const onOk = () => {
    const quantity = form.getFieldValue('quantity');

    if (quantity) {
      mutate({ warehouseId, warehouseMaterialId, action, quantity });
    }
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      refetch();
      setIsOpen(false);
    }
  }, [isSuccess]);

  return (
    <Modal title="Aggiungi Materiale" open={isOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="load-material" layout="vertical">
          <Form.Item
            name="quantity"
            label="Quantità"
            initialValue={1}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
