import React, { useEffect } from 'react';
import { Alert, Col, Form, Input, Modal } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isCreateSupplierModalOpenState, selectedSupplierState } from './atoms';
import { useGetSuppliers } from '@api/suppliers/useGetSuppliers';
import { useCreateSupplier } from '@api/suppliers/useCreateSupplier';
import { useNavigate } from 'react-router-dom';

export function CreateSupplierModal() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const setSelectedSupplier = useSetRecoilState(selectedSupplierState);
  const [open, setOpen] = useRecoilState(isCreateSupplierModalOpenState);

  const { refetch: refetchSuppliers } = useGetSuppliers();
  const { mutate: create, isSuccess, error, reset, data } = useCreateSupplier();

  useEffect(() => {
    if (isSuccess) {
      refetchSuppliers().then(() => {
        reset();
        form.resetFields();
        setOpen(false);
        navigate(`/registry/suppliers/${data.data.id}`);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    create({ ...form.getFieldsValue() });
  };

  const onCancel = () => {
    reset();
    setSelectedSupplier(null);
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal title="Crea Fornitore" open={open} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-supplier" layout="vertical">
          <Form.Item name="name" label="Nome">
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
