import { Alert, Col, Form, Input, Modal } from 'antd';
import { useAddWarehouseMaterial, useGetWarehouseMaterials } from '@api/warehouses';
import { useRecoilState } from 'recoil';
import { isAddWarehouseMaterialModalOpenState, selectedWarehouseIdState } from '../atoms';
import { useEffect } from 'react';


export function AddWarehouseMaterialModal() {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useRecoilState(isAddWarehouseMaterialModalOpenState);
  const [warehouseId] = useRecoilState(selectedWarehouseIdState);

  const { refetch } = useGetWarehouseMaterials(warehouseId);
  const { mutate: addWarehouseMaterial, isSuccess, reset, error, data } = useAddWarehouseMaterial();

  const onOk = () => {
    form.validateFields()
      .then(() => {
        addWarehouseMaterial({ warehouseId, description: form.getFieldValue('description') });
      })
      .catch(() => {
      });
  };

  const onCancel = () => {
    form.resetFields();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      reset();
      setIsOpen(false);
      refetch();
    }
  }, [isSuccess]);

  return (
    <Modal title="Aggiungi Materiale" open={isOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="add-material" layout="vertical">
          <Form.Item
            name="description"
            label="Descrizione"
            rules={[{ required: true, message: 'Per favore inserisci una descrizione' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
