import { atom } from 'recoil';
import { CommissionMaterial } from '@api/types/commissions';

export const isCreateCommissionModalOpenState = atom({
  key: 'isCreateCommissionModalOpenState',
  default: false,
});

export const isCreateCommissionOrderModalOpenState = atom({
  key: 'isCreateCommissionOrderModalOpenState',
  default: false,
});

export const selectedCommissionMaterialState = atom<CommissionMaterial | null>({
  key: 'selectedCommissionMaterialState',
  default: null,
});
