import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { useSetRecoilState } from 'recoil';
import {
  CreateReferrerModal,
  DeleteReferrerModal,
  isCreateReferrerModalOpenState,
  ReferrersTable,
  UpdateReferrerModal,
} from './components/referrers';


export function Referrers() {
  const setIsCreateReferrerModalOpen = useSetRecoilState(isCreateReferrerModalOpenState);

  const onNew = () => {
    setIsCreateReferrerModalOpen(true);
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Segnalatori"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Segnalatore</Button>}
        >
          <ReferrersTable />
          <CreateReferrerModal />
          <UpdateReferrerModal />
          <DeleteReferrerModal />
        </Card>
      </Col>
    </Row>
  );
}
