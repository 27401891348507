import { Card, Col, Row } from 'antd';

export function Bending() {
  return (
    <Row>
      <Col span={24}>
        <Card title="Piegatura">

        </Card>
      </Col>
    </Row>
  );
}
