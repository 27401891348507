import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { Material } from '@aternum-software/abruzzo-lamiere-common';
import { QueryKey } from '@constants/queries';

async function updateMaterial(material: Material): Promise<Material> {
  const response = await axios.put<Material>(`${apiBaseUrl}/materials/${material.id}`, material);

  return response.data;
}

export function useUpdateMaterial() {
  return useMutation({
    mutationKey: [QueryKey.UpdateMaterial],
    mutationFn: updateMaterial,
  });
}
