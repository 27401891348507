import { atom } from 'recoil';
import { Referrer } from '@aternum-software/abruzzo-lamiere-common';


export const selectedReferrerState = atom<Referrer | null>({
  key: 'selectedReferrerState',
  default: null,
});

export const isCreateReferrerModalOpenState = atom({
  key: 'isCreateReferrerModalOpenState',
  default: false,
});

export const isEditReferrerModalOpenState = atom({
  key: 'isEditReferrerModalOpenState',
  default: false,
});

export const isDeleteReferrerModalOpenState = atom({
  key: 'isDeleteReferrerModalOpenState',
  default: false,
});
