import { atom } from 'recoil';
import { Source } from '@aternum-software/abruzzo-lamiere-common';


export const selectedSourceState = atom<Source | null>({
  key: 'selectedSourceState',
  default: null,
});

export const isCreateSourceModalOpenState = atom({
  key: 'isCreateSourceModalOpenState',
  default: false,
});

export const isEditSourceModalOpenState = atom({
  key: 'isEditSourceModalOpenState',
  default: false,
});

export const isDeleteSourceModalOpenState = atom({
  key: 'isDeleteSourceModalOpenState',
  default: false,
});
