import { Breadcrumb, Button, Card, Col, Form, Input, InputNumber, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetMaterial } from '@api/materials/useGetMaterial';
import { useUpdateMaterial } from '@api/materials/useUpdateMaterial';
import { Material as MaterialType } from '@aternum-software/abruzzo-lamiere-common';
import { useEffect } from 'react';
import { useNotification } from '@hooks/useNotification';

export function Material() {
  const params = useParams();

  console.log('params', params);

  const { contextHolder, openInfoMessage } = useNotification();

  const { data: material, refetch: refetchMaterial, isLoading } = useGetMaterial(params?.id ?? '');

  const {
    mutate: updateMaterial,
    isSuccess: isSuccessUpdateMaterial,
    reset: resetUpdateMaterial,
  } = useUpdateMaterial();

  const onFinish = (payload: MaterialType) => {
    updateMaterial({ ...payload, id: material?.id ?? '' });
  };

  useEffect(() => {
    if (isSuccessUpdateMaterial) {
      resetUpdateMaterial();
      refetchMaterial();
      openInfoMessage('Materiale aggiornato con successo');
    }
  }, [isSuccessUpdateMaterial]);

  const items = [
    { title: <Link to="/registry/materials">Materiali</Link> },
    { title: material?.description ?? '' },
  ];

  if (isLoading) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={<Button type="primary" htmlType="submit" form="edit-material">Salva</Button>}
        >
          <Form name="edit-material" layout="vertical" initialValues={material} onFinish={onFinish}>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="description"
                  label="Descrizione"
                  rules={[{ required: true, message: 'Per favore inserisci una descrizione' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item name="brand" label="Marca">
                  <Input />
                </Form.Item>

                <Form.Item name="color" label="Colore">
                  <Input />
                </Form.Item>

                <Form.Item name="material" label="Materiale">
                  <Input />
                </Form.Item>

                <Form.Item name="finish" label="Finitura">
                  <Input />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="length" label="Lunghezza">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item name="width" label="Larghezza">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item name="height" label="Altezza">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="thickness" label="Spessore">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item name="diameter" label="Diametro">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item name="depth" label="Profondità">
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Form.Item name="note" label="Note">
                  <Input.TextArea rows={12} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      {contextHolder}
    </Row>
  );
}
