import { Alert, Col, Form, Input, Modal } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isCreateReferrerModalOpenState, selectedReferrerState } from './atoms';
import React, { useEffect } from 'react';
import { useCreateQuotationReferrer, useGetQuotationReferrers } from '@api/quotations';

export function CreateReferrerModal() {
  const [form] = Form.useForm();
  const setSelectedReferrer = useSetRecoilState(selectedReferrerState);
  const [isCreateReferrerModalOpen, setIsCreateReferrerModalOpen] = useRecoilState(isCreateReferrerModalOpenState);

  const { refetch: refetchQuotationReferrers } = useGetQuotationReferrers();
  const { mutate: createQuotationReferrer, isSuccess, error, reset } = useCreateQuotationReferrer();

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationReferrers().then(() => {
        reset();
        form.resetFields();
        setIsCreateReferrerModalOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    createQuotationReferrer({ ...form.getFieldsValue() });
  };

  const onCancel = () => {
    reset();
    setSelectedReferrer(null);
    form.resetFields();
    setIsCreateReferrerModalOpen(false);
  };

  return (
    <Modal title="Crea Segnalatore" open={isCreateReferrerModalOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Form form={form} name="create-referrer" layout="vertical">
          <Form.Item name="description" label="Descrizione">
            <Input />
          </Form.Item>
        </Form>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-referrer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
