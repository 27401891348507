import { atom } from 'recoil';
import { Supplier } from '@aternum-software/abruzzo-lamiere-common';


export const selectedSupplierState = atom<Supplier | null>({
  key: 'selectedSupplierState',
  default: null,
});

export const isCreateSupplierModalOpenState = atom({
  key: 'isCreateSupplierModalOpenState',
  default: false,
});

export const isDeleteSupplierModalOpenState = atom({
  key: 'isDeleteSupplierModalOpenState',
  default: false,
});

export const isAddSupplierMaterialModalOpenState = atom({
  key: 'isAddSupplierMaterialModalOpenState',
  default: false,
});

export const isUpdateSupplierMaterialModalOpenState = atom({
  key: 'isUpdateSupplierMaterialModalOpenState',
  default: false,
});

export const isDeleteSupplierMaterialModalOpenState = atom({
  key: 'isDeleteSupplierMaterialModalOpenState',
  default: false,
});
