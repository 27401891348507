import { Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { useSetRecoilState } from 'recoil';
import { isCreateOrderModalOpenState } from './atoms';
import { CreateOrderModal } from './components/CreateOrderModal';
import { getOrderStatusLabel } from './utils';
import { format } from 'date-fns';
import { useUpdateOrder } from '@api/orders/useUpdateOrder';
import { Order } from '@api/types';
import { useGetOrders } from '@api/index';

export function Orders() {
  const navigate = useNavigate();
  // const { data: orders, refetch: refetchOrders } = useGetOrders();

  const { data: orders, refetch: refetchOrders } = useGetOrders();

  const { mutate: updateOrder, isSuccess: isSuccessUpdateOrder, reset: resetUpdateOrder } = useUpdateOrder();

  const setIsCreateOrderModalOpen = useSetRecoilState(isCreateOrderModalOpenState);

  const onNew = () => {
    setIsCreateOrderModalOpen(true);
  };

  const onSend = (order: Order) => {
    updateOrder({ orderId: order.id, order: { status: 'sent' } });
  };

  const onConfirm = (order: Order) => {
    updateOrder({ orderId: order.id, order: { status: 'confirmed' } });
  };

  const onEdit = (id: string) => {
    navigate(`/orders/${id}`);
  };

  useEffect(() => {
    if (isSuccessUpdateOrder) {
      resetUpdateOrder();
      refetchOrders();
    }
  }, [isSuccessUpdateOrder]);

  const columns: ColumnProps<Order>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>OFABL{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getOrderStatusLabel(text)}</Typography>,
    },
    {
      title: 'Fornitore',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier) => <Typography>{supplier?.name ?? ''}</Typography>,
    },
    {
      title: 'Inviato',
      dataIndex: 'sentAt',
      key: 'sentAt',
      render: (_, record) =>
        <Typography>{record.sentAt ? format(record.sentAt, 'dd-MM-yyyy') : 'Non Inviato'}</Typography>,
    },
    {
      title: 'Confermato',
      dataIndex: 'confirmedAt',
      key: 'confirmedAt',
      render: (_, record) =>
        <Typography>{record.confirmedAt ? format(record.confirmedAt, 'dd-MM-yyyy') : 'Non Confermato'}</Typography>,
    },
    {
      title: 'Arrivato',
      dataIndex: 'arrivedAt',
      render: (_, record) =>
        <Typography>{record.arrivedAt ? format(record.arrivedAt, 'dd-MM-yyyy') : 'Non Arrivato'}</Typography>,
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          {record.status === 'draft' && <Button type="link" onClick={() => onSend(record)}>Invia</Button>}
          {record.status === 'sent' && <Button type="link" onClick={() => onConfirm(record)}>Conferma</Button>}
          <Button
            type="link"
            onClick={() => onEdit(record.id)}
          >{record.status === 'draft' ? 'Modifica' : 'Visualizza'}</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Ordini a Fornitore" extra={<Button type="primary" onClick={onNew}>Nuovo Ordine</Button>}>
          <Table
            columns={columns}
            dataSource={orders}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: orders?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
      <CreateOrderModal />
    </Row>
  );
}
