import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryKey } from '@constants/queries';
import {
  UpdateUserProfileRequest,
  UserChangePasswordRequest,
  UserChangePasswordResponse,
  UserProfileResponse,
} from '@aternum-software/abruzzo-lamiere-common';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';


const fetchProfile = async (): Promise<UserProfileResponse | null> => {
  try {
    const response = await axios.get<UserProfileResponse>(`${process.env.REACT_APP_API_BASE_PATH}/profile`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const fetchUsers = async (): Promise<UserProfileResponse[] | []> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/users`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const fetchUser = async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/users/${id}`);
    return response.data;
  } catch (error) {
    return {};
  }
};

export const updateUser = async (user: UpdateUserProfileRequest): Promise<UserProfileResponse | null> => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_PATH}/users`, user);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteUser = async (id: string): Promise<null> => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_PATH}/users/${id}/disable`);

    return response.data;
  } catch (error) {
    return null;
  }
};

export function changePassword(payload: { id: string, data: UserChangePasswordRequest }) {
  return axios.put(`${apiBaseUrl}/${ApiPath.Users}/${payload.id}/change-password`, payload.data);
}

export const useGetProfile = (config?: { enabled: boolean }) => useQuery({
  queryKey: [QueryKey.GetProfile],
  queryFn: fetchProfile,
  enabled: config?.enabled ?? true,
});

export const useGetUsers = () => useQuery({
  queryKey: [QueryKey.GetUsers],
  queryFn: fetchUsers,
});

export const useGetUser = (id: string) => useQuery({
  queryKey: [QueryKey.GetUser, id],
  queryFn: () => fetchUser(id),
  enabled: id !== '',
});

export const useUpdateUser = () => useMutation({
  mutationFn: updateUser,
  mutationKey: [QueryKey.UpdateUser],
});

export const useChangePassword = () => useMutation<AxiosResponse<UserChangePasswordResponse>, AxiosError<{
  message: string[]
}>, { id: string, data: UserChangePasswordRequest }>({
  mutationFn: changePassword,
  mutationKey: [QueryKey.UserChangePassword],
});
