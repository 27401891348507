import { Alert, Col, Modal, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import { isDeleteSourceModalOpenState, selectedSourceState } from './atoms';
import React, { useEffect } from 'react';
import { useDeleteQuotationSource, useGetQuotationSources } from '@api/quotations';


export function DeleteSourceModal() {
  const [selectedSource, setSelectedSource] = useRecoilState(selectedSourceState);
  const [isDeleteSourceModalOpen, setIsDeleteSourceModalOpen] = useRecoilState(isDeleteSourceModalOpenState);

  const { refetch: refetchQuotationSources } = useGetQuotationSources();
  const { mutate: deleteQuotationSource, isSuccess, error, reset } = useDeleteQuotationSource();

  useEffect(() => {
    if (isSuccess) {
      refetchQuotationSources().then(() => {
        reset();
        setIsDeleteSourceModalOpen(false);
      });
    }
  }, [isSuccess]);

  const onOk = () => {
    if (selectedSource) {
      deleteQuotationSource(selectedSource.id);
    }
  };

  const onCancel = () => {
    reset();
    setSelectedSource(null);
    setIsDeleteSourceModalOpen(false);
  };

  return (
    <Modal title="Elimina Provenienza" open={isDeleteSourceModalOpen} onOk={onOk} onCancel={onCancel}>
      <Col style={{ marginTop: 20, marginBottom: 40 }}>
        <Typography>Sei sicuro di voler eliminare la provenienza?</Typography>
      </Col>

      <Col style={{ marginTop: 40, marginBottom: 20 }}>
        {error?.response?.data.message && (
          <Alert
            message={error?.response?.data.message}
            key="delete-customer-error"
            type="error"
            showIcon
          />
        )}
      </Col>
    </Modal>
  );
}
