import { Referrer } from '@aternum-software/abruzzo-lamiere-common';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';


function updateQuotationReferrer(referrer: Referrer) {
  return axios.put(`${apiBaseUrl}/${ApiPath.QuotationReferrers}`, referrer);
}

export function useUpdateQuotationReferrer() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, Referrer>({
    mutationKey: [QueryKey.UpdateQuotationReferrer],
    mutationFn: updateQuotationReferrer,
  });
}
