import { Button, Card, Col, Row, Space, Spin, Table } from 'antd';
import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Material } from '@aternum-software/abruzzo-lamiere-common';
import { useGetMaterials } from '@api/materials/useGetMaterials';
import { useNavigate } from 'react-router-dom';

export function Materials() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetMaterials();

  const onNew = () => {

  };

  const onEdit = (material: Material) => {
    navigate(`/registry/materials/${material.id}`);
  };

  const onDelete = (material: Material) => {

  };

  const columns: ColumnProps<Material>[] = [
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Materiali"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Materiale</Button>}
        >
          <Spin spinning={isLoading}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                responsive: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
                total: data?.length ?? 0,
              }}
              rowKey="id"
              size="small"
            />
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}
