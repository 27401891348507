import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { useSetRecoilState } from 'recoil';
import {
  CreateSourceModal,
  DeleteSourceModal,
  isCreateSourceModalOpenState,
  SourcesTable,
  UpdateSourceModal,
} from './components/sources';


export function Sources() {
  const setIsCreateSourceModalOpen = useSetRecoilState(isCreateSourceModalOpenState);

  const onNew = () => {
    setIsCreateSourceModalOpen(true);
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Provenienze"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Provenienza</Button>}
        >
          <SourcesTable />
          <CreateSourceModal />
          <UpdateSourceModal />
          <DeleteSourceModal />
        </Card>
      </Col>
    </Row>
  );
}
