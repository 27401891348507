import { Referrer } from '@aternum-software/abruzzo-lamiere-common';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { ApiPath } from '@constants/index';


function createQuotationReferrer(referrer: Partial<Referrer>) {
  return axios.post(`${apiBaseUrl}/${ApiPath.QuotationReferrers}`, referrer);
}

export function useCreateQuotationReferrer() {
  return useMutation<AxiosResponse, AxiosError<{ message: string }>, Partial<Referrer>>({
    mutationKey: [QueryKey.CreateQuotationReferrer],
    mutationFn: createQuotationReferrer,
  });
}
