import { Button, Card, Col, Row } from 'antd';
import { useSetRecoilState } from 'recoil';
import { isCreateSupplierModalOpenState, SuppliersTable } from './components/suppliers';
import React from 'react';
import { CreateSupplierModal } from './components/suppliers/CreateSupplierModal';
import { DeleteSupplierModal } from './components/suppliers/DeleteSupplierModal';

export function Suppliers() {
  const setIsCreateSupplierModalOpen = useSetRecoilState(isCreateSupplierModalOpenState);

  const onNew = () => {
    setIsCreateSupplierModalOpen(true);
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Fornitori"
          extra={<Button type="primary" onClick={onNew}>Aggiungi Fornitore</Button>}
        >
          <SuppliersTable />
        </Card>
      </Col>
      <CreateSupplierModal />
      <DeleteSupplierModal />
    </Row>
  );
}
